@import '../variables';

.contact {
  padding: 50px 30px 50px;
  width: 100%;
  background-color: $red;
  display: flex;
  justify-content: center;
  align-items: center;

  div:nth-of-type(1) {
    max-width: 660px;
    width: 100%;

    h3 {
      @include index-section-title($white);
    }
    
    p {
      color: $white;
      font-size: 1.1em;
      font-family: 'Noto Sans JP medium';
      line-height: 1.6;
    }
  }

  div:nth-of-type(2) {
    width: 300px;
    min-width: 300px;

    > a {
      @include main-button();

      &:nth-of-type(1) {
        margin: 0 0 30px 0;
      }
    }
  }
}

.top {
  position: relative;
  overflow: hidden;
}

.linkRelatedContent {
  position: absolute;
  bottom: 1px;
  left: 15%;
  z-index: 1;

  ul {
    display: flex;

    li {
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 20px 10px;
        border: 1px $border-black solid;
        border-bottom: unset;
      }

      & + li {
        a {
          border-left: unset;
        }
      }
    }
  }
} 

.form {
  margin: 100px 0 100px 0;
  padding: 0 30px;

  h3 {
    text-align: center;
    font-size: 26px;
    font-family: 'Noto Sans JP medium';
    font-weight: bold;
    letter-spacing: .05em;
  }

  form {
    max-width: 900px;
    width: 100%;
    margin: 60px auto 0;

    dl {
      > div {
        display: flex;

        dt {
          max-width: 330px;
          width: 100%;
          font-family: 'Noto Sans JP medium';
          font-weight: bold;

          span {
            margin: 0 0 0 15px;
            padding: 4px 10px;
            font-size: .5em;
            background-color: $red;
            color: $white;
            vertical-align: middle;
            display: inline-block;
          }
        }

        &.form01 {
          input {
            cursor: pointer;
          }

          label {
            padding: 10px 0 10px 15px;
            white-space: pre-wrap;
          }

          dd + dd + dd {
            margin: 30px 0 0 0;
          }
        }

        &.form02 {
          input {
            cursor: pointer;
          }

          label {
            padding: 10px 0 10px 15px;
            white-space: pre-wrap;
          }

          dd + dd {
            margin: 30px 0 0 0;
          }

          textarea {
            margin: 20px 0 0 0;
          }

        }

        &.form03 {
          div {
            dd:nth-of-type(1) {
              input {
                width: 45%;
                padding: 10px 15px;
                margin: 0 0 0 10px;
                letter-spacing: .05em;
                background-color: rgba(36, 36, 36, 0.08);
              }
            }

            dd:nth-of-type(2) {
              margin: 30px 0 0 0;

              input {
                width: 100%;
                padding: 10px 15px;
                letter-spacing: .05em;
                background-color: rgba(36, 36, 36, 0.08);
              }
            }
          }
        }

        & + div {
          margin: 90px 0 0 0;
        }

        > div {
          width: calc(100% - 330px);
        }

        dd {
          &.contactWid {
            width: calc(100% - 330px);

            input {
              width: 100%;
              padding: 10px 15px;
              letter-spacing: .05em;
              background-color: rgba(36, 36, 36, 0.08);
            }

            &.half {
              input {
                letter-spacing: .05em;
                width: 50%;
              }
            }
          }
        }
        
        input::placeholder {
          letter-spacing: .05em;
          color: #2424244d;
        }

        textarea {
          width: 100%;
          height: 250px;
          padding: 10px 15px;
          resize: none;
          background-color: rgba(36, 36, 36, 0.08);

          &::placeholder {
            letter-spacing: .05em;
            color: rgba(36, 36, 36, .30);
          }
        }

        select {
          width: 50%;
          padding: 10px 15px;
          cursor: pointer;
          border: 1px $border-black solid;
          letter-spacing: .05em;
          position: relative;
          background: $white url(../../img/polygon01.svg) no-repeat right 10px center;
        }
      }

      .form01 {
        > div {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }

      small {
        display: block;
        margin: 5px 0 0 0;
        color: $red;
        font-weight: bold;

        &::before {
          content: '';
          width: 15px;
          height: 15px;
          margin: 0 10px 0 0;
          vertical-align: text-bottom;
          background-image: url(../../img/icon-attention.svg);
          background-size: contain;
          background-repeat: no-repeat;
          display: inline-block;
        }
      }
    }

    .submit {
      display: block;
      width: 320px;
      padding: 20px 0;
      margin: 80px auto 10px;
      color: $black;
      font-weight: bold;
      text-align: center;
      border: 1px solid $black;
    
      background-color: unset;
      transition: .3s;
    
      &:hover {
        color: $white;
        background-color: $black;
      }
    }
    > small {
      display: block;
      margin: 0 auto;
      color: $red;
      font-weight: bold;
      text-align: center;

      &::before {
        content: '';
        width: 15px;
        height: 15px;
        margin: 0 10px 0 0;
        vertical-align: text-bottom;
        background-image: url(../../img/icon-attention.svg);
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
      }
    }
  }
}

@include media(tab) {
  .contact {
    padding: 50px 30px 50px;
  }

  .linkRelatedContent {
    position: unset;
    width: 100%;

    ul {
      width: 100%;
      flex-wrap: wrap;

      li {
        width: calc(100% / 2);
        text-align: center;

        a {
          padding: 10px 8px;
          font-size: 14px;
          border: unset;
          border-bottom: 1px $border-black solid;
          
          &:nth-of-type(2n - 1) {
            border-right: 1px $border-black solid;
          }
        }
      }
    }
  }

  .form {
    form {
      max-width: 600px;

      dl {
        > div {
          flex-direction: column;

          dt {
            max-width: unset;
            margin: 0 0 30px 0;
          }

          > div {
            width: 100%;
          }

          dd {
            &.contactWid {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@include media(sp) {
  .contact {
    flex-direction: column;
    padding: 50px 30px;

    p {
      font-size: .9em;
      line-height: 1.5;
    }

    div:nth-of-type(1) {
      text-align: center;

      h3 {
        font-size: 3em;
      }

      p {
        font-size: .9em;
        line-height: 1.5;
      }
    }

    div:nth-of-type(2) {
      max-width: 300px;
      width: 100%;
      margin: 30px 0 0 0;
      
      a {
        font-size: .9em;
      }
    }
  }

  .form {
    margin: 60px 0;
    padding: 0 20px;

    h3 {
      font-size: 24px;
    }
    
    form {
      margin: 30px auto 0;

      dl {
        font-size: .9em;
        line-height: 1.5;

        > div {

          &.form01 {
            > div {

              flex-direction: column;
            }
            
            dd + dd {
              margin: 30px 0 0;
            }
          }
          & + div {
            margin: 50px 0 0 0;
          }
          select {
            width: 100%;
          }
          dd.contact-wid.half {
            input {

              width: 100%;
            }
          }
          &.form03 {
            div {
              dd:nth-of-type(1) {
                input {
                  width: calc(100% - 28px);
                }
              }
            }
          }
        }
      }

      .submit {
        max-width: 320px;
        width: 100%;
        margin: 50px auto 30px;
        font-size: .9em;
      }
    }
  }
}