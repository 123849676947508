@import '../variables';

.top {
  position: relative;
  overflow: hidden;
}

.linkRelatedContent {
  position: absolute;
  bottom: 1px;
  left: 15%;
  z-index: 1;

  ul {
    display: flex;

    li {
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 20px 10px;
        border: 1px $border-black solid;
        border-bottom: unset;
      }

      &.lists {
        background-color: $white;
        color: $black;
        transition: .3s;

        &:hover {
          background-color: $black;
          color: $white;
        }

        &.active {
          background-color: $black;
          color: $white;
        }
      }

      & + li {
        a {
          border-left: unset;
        }
      }
    }
  }
} 

.lowerpage {
  width: 100%;
  padding: 100px 0 0;
  line-height: 1.75;
  font-size: 1.2em;
  text-align: center;
  background-color: $white;
  position: relative;
  z-index: 10;

  .sp {
    display: none;
  }
}

.content01 {
  width: 100%;
  margin: 120px 0 0 0;
  padding: 100px 30px;
  background-color: $red;
  color: $white;

  h3 {
    color: $white;
    text-align: center;
    font-size: 26px;
    font-family: 'Noto Sans JP medium';
    font-weight: bold;
    letter-spacing: .05em;
  }

  > ul {
    max-width: 800px;
    width: 100%;
    margin: 50px auto 0;

    li {
      padding: 0 0 0 60px;
      color: $white;
      font-size: 1.1em;
      line-height: 1.5;
      position: relative;

      span {
        color: $white;
        font-family: 'Noto Sans JP medium';
        font-weight: bold;
      }

      & + li {
        margin: 40px 0 0 0;
      }

      &::before {
        content: '';
        background-image: url(../../img/check.svg);
        width: 33px;
        height: 33px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.content02 {
  margin: 20px 0 0 0;
  padding: 120px 6% 0;

  h3 {
    text-align: center;
    font-size: 26px;
    font-family: 'Noto Sans JP medium';
    font-weight: bold;
    letter-spacing: .05em;
  }

  > div {
    max-width: 800px;
    width: 100%;
    margin: 60px auto 50px;
    display: flex;
    justify-content: center;

    article {
      width: calc((100% - 60px) / 3);
      margin: 0 10px;

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        text-align: left;

        > div {
          position: relative;

          div {
            position: relative;
            width: 100%;
            height: 61.8%;
            overflow: hidden;
  
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              display: block;
              width: 100%;
              height: 100%;
              content: '';
            }

            &::after {
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
              color: $white;
              font-size: 14px;
              font-family: 'Noto Sans JP medium';
              content: '詳しく見る ＞';
              opacity: 0;
              visibility: hidden;
              transition: .3s;
            }

            img {
              width: 100%;
              height: 100%;
              transform: scale(1);
              transition: .3s;
            }
          }

          span {
            padding: 5px 10px;
            color: $white;
            background-color: $red;
            font-size: 0.75em;
            font-weight: bold;
            position: absolute;
            bottom: -10px;
            left: -10px;
            z-index: 1;
          }
        }

        h4 {
          margin: 25px 0 0;
          font-size: 15px;
          font-family: 'Noto Sans JP medium';
          font-weight: bold;
          line-height: 1.35;
          letter-spacing: 0;
        }

          
        ul {
          margin: 10px 0 0 0;
          display: flex;
          flex-wrap: wrap;
  
          li {
            padding: 5px 10px;
            border: 1px $border-black solid;
            font-size: .6em;
            font-weight: bold;
  
            & + li {
              margin: 0 0 0 10px;
            }
          }
        }

        &:hover {
          > div {

            div::after {
              opacity: 1;
              visibility: visible;
            }

            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }

  > a {
    @include main-button();
  }
}

.content03 {
  margin: 20px 0 0 0;
  padding: 120px 30px 0;
  text-align: center;

  h3 {
    text-align: center;
    font-size: 26px;
    font-family: 'Noto Sans JP medium';
    font-weight: bold;
    letter-spacing: .05em;
  }

  article {
    max-width: 800px;
    width: 100%;
    margin: 40px auto 0;
    text-align: left;
    line-height: 1.8;

    h4 {
      font-size: 20px;
      font-family: 'Noto Sans JP medium';
      font-weight: bold;
      color: $red;
    }

    p:nth-of-type(1) {
      margin: 10px 0 0 0;
    }

    & + article {
      margin: 30px auto 0;
    }
  }

  > div {
    cursor: pointer;
    display: block;
    width: 320px;
    padding: 20px 0;
    margin: 80px auto;
    color: $black;
    font-weight: bold;
    text-align: center;
    border: 1px solid $black;
  
    background-color: unset;
    transition: .3s;
  
    &:hover {
      color: $white;
      background-color: $black;
    }
  }

  section {
    padding: 140px 0 0 0;
    text-align: left;

    h3 {
      margin: 0 0 30px 0;
      font-size: 26px;
      font-weight: bold;
      text-align: center;
    }

    p {
      max-width: 800px;
      width: 100%;
      margin: 0 auto;
      line-height: 1.75;
    }

    dl {
      max-width: 800px;
      width: 100%;
      margin: 60px auto 0;
      position: relative;

      div {
        padding: 40px 30px 40px 70px;
        border-top: 1px $border-black solid;

        dt {
          position: relative;
          padding: 0 0 0 30px;
          font-size: 18px;
          font-family: 'Noto Sans JP medium';
          font-weight: bold;

          span {
            margin: 0 0 0 10px;
            display: inline-block;
            font-size: .7em;
          }
        }

        dd {
          margin: 15px 0 0 0;
          line-height: 1.75;
        }

        @for $i from 1 to 6 {
          &:nth-of-type(#{$i}) {
            dt::before {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate(0, -50%);
              font-size: 24px;
              font-family: 'Noto Sans JP medium';
              font-weight: bold;
              color: $red;
              content: '#{$i}';
            }
          }
        }
      }
      
      &::before {
        content: '';
        width: 40px;
        height: 100%;
        background-image: url(../../img/arrow-big.svg);
        background-size: 40px 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.content04 {
  margin: 100px 0 0 0;
  padding: 120px 6% 30px;
  text-align: center;
  background-color: $gray;

  h3 {
    margin: 0 0 30px 0;
    font-size: 26px;
    font-family: 'Noto Sans JP medium';
    font-weight: bold;
    text-align: center;
  }

  p {
    margin: 20px 0 0 0;
    line-height: 1.65;
  }

  ul {
    max-width: 800px;
    width: 100%;
    margin: 20px auto 0;
    padding: 0 0 60px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    justify-content: flex-start;

    li {
      width: calc((100% - 60px) / 3);
      margin: 10px 10px 0;
      padding: 20px 30px 25px;
      background-color: $white;
      border: $border-black 1px solid;

      h4 {
        display: flex;
        align-items: center;
        height: 37px;
        font-family: 'Noto Sans JP medium';
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1.35;
      }

      p {
        margin: 10px 0 0 0;
        font-size: 15px;
        line-height: 1.75;
      }
    }
  }
}

@include media(tab) {
  .linkRelatedContent {
    position: unset;
    width: 100%;

    ul {
      width: 100%;
      flex-wrap: wrap;

      li {
        width: calc(100% / 2);
        text-align: center;

        a {
          padding: 10px 8px;
          font-size: 14px;
          border: unset;
          border-bottom: 1px $border-black solid;
          
          &:nth-of-type(2n - 1) {
            border-right: 1px $border-black solid;
          }
        }
      }
    }
  }

  .lowerpage {
    font-size: 16px;
  }

  .content01 {
    > ul {
      li {
        line-height: 1.5;
      }
    }
  }

  .content02 {
    > div {
      article {
        height: 280px;
        margin: 40px auto 0;

        a {
          ul {
            flex-wrap: wrap;

            li {
              font-size: .6em;
            }
          }
        }
      }
    }
  }

  .content03 {
    h3 {
      font-size: 26px;
    }
  }

  .content04 {
    h3 {
      font-size: 26px;
    }

    ul {
      li {
        width: calc((100% - 60px) / 2);
        border-bottom: 1px $border-black solid;
      }
    }
  }
}

@include media(sp) {
  .lowerpage {
    padding: 40px 20px 0;
    text-align: left;

      .pc {
        display: none;
      }
  }

  .content01 {
    margin: 60px 0 0 0;
    padding: 80px 20px 60px;

    h3 {
      margin: 0 0 30px 0;
      font-size: 1.2em;
      line-height: 1.5;
    }

    > ul {
      li {
        font-size: .9em;
      }
    }
  }

  .content02 {
    margin: 0;
    padding: 80px 20px 0;

    h3 {
      font-size: 1.2em;
      line-height: 1.5;
    }

    > div {
      max-width: 350px;
      margin: 0 auto 20px;
      flex-direction: column;

      article {
        width: 100%;
        height: auto;

        a {
          padding: 0 0 20px 0;
        }
      }
    }
    a {
      font-size: .9em;
    }
  }

  .content03 {
    margin: 0;
    padding: 80px 20px 0;

    h3 {
      font-size: 1.2em;
      line-height: 1.5;
    }

    article {
      font-size: .9em;

      h4 {
        font-size: 1em;
      }
    }
    > div {
      max-width: 320px;
      width: 100%;
      margin: 40px auto;
      font-size: .9em;
    }
    section {
      margin: 30px 0 0 0;
      padding: 0;

      h3 {
        margin: 0 0 30px 0;
        font-size: 1.2em;
        line-height: 1.5;
      }

      p {
        font-size: .9em;
      }

      dl {
        margin: 30px auto 0;

        div {
          padding: 40px 10px 40px 50px;
          dt {
            font-size: .9em;
            span {
              margin: 0 10px 0 0;
            }
          }

          dd {
            margin: 10px 0 0 22px;
            font-size: .9em;
          }
        }
      }
    }
  }

  .content04 {
    margin: 60px 0 0 0;
    padding: 100px 20px 60px;

    h3 {
      font-size: 1.2em;
      line-height: 1.5;
    }

    p {
      font-size: .9em;
      line-height: 1.5;
    }

    ul {
      padding: 20px 0;
      flex-direction: column;

      li {
        max-width: 350px;
        width: 100%;
        margin: 10px 0 0;
      }
    }
  }
}