@import '../variables';

.lowerpage {
  width: 100%;
  padding: 100px 0 0;
  line-height: 1.75;
  font-size: 1.2em;
  text-align: center;
  background-color: $white;
  position: relative;
  z-index: 10;

  .sp {
    display: none;
  }
}

.services01 {
  padding: 140px 6% 0;
  text-align: center;

  h3 {
    text-align: center;
    font-size: 2.2em;
    font-family: 'Noto Sans JP medium';
    font-weight: bold;
    letter-spacing: .05em;
  }

  ul {
    width: 100%;
    margin: 40px auto 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      width: calc((100% - 60px) / 4);
      margin: 40px 10px 0;

      &:nth-of-type(1),
      &:nth-of-type(5) {
        margin: 40px 10px 0 0;
      }

      &:nth-of-type(4),
      &:nth-of-type(7) {
        margin: 40px 0 0 10px;
      }

      a {
        display: block;
        width: 100%;

        div {
          position: relative;
          width: 100%;
          height: 61.8%;
          overflow: hidden;

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            content: '';
          }

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            color: $white;
            font-size: 14px;
            font-family: 'Noto Sans JP medium';
            content: '詳しく見る ＞';
            opacity: 0;
            visibility: hidden;
            transition: .3s;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: .3s;
          }
        }

        h4 {
          position: relative;
          display: flex;
          align-items: center;
          height: 37px;
          margin: 20px 0 0 0;
          padding: 0 0 0 35px;
          white-space: pre-wrap;
          font-family: "Noto Sans JP medium";
          text-align: left;
        }

        p {
          margin: 8px 0 0 0;
          text-align: left;
          font-size: 15px;
          line-height: 1.6;
        }

        &:hover {
          div {
            img {
              transform: scale(1.1)
            }

            &::after {
              opacity: 1;
              visibility: visible
            }
          }
        }
      }

      $icons: 'service01', 'service02', 'service03', 'service04', 'service05', 'service06', 'service07';
      @each $icon in $icons {
        &.#{$icon} {
          a {
            h4::before {
              position: absolute;
              top: 55%;
              left: 0;
              transform: translate(0, -50%);
              width: 25px;
              height: 25px;
              background: no-repeat url(../../img/#{$icon}.png);
              background-size: 100%;
              content: '';
            }
          }
        }
      }
    }
  }
}

.services02 {
  margin: 140px 0 0 0;
  padding: 140px 30px 140px;
  background-color: $gray;

  h3 {
    text-align: center;
    font-size: 2.2em;
    font-family: 'Noto Sans JP medium';
    font-weight: bold;
    letter-spacing: .05em;
  }

  p {
    max-width: 700px;
    width: 100%;
    margin: 40px auto 0;
    line-height: 1.5;
  }

  dl {
    max-width: 960px;
    width: 100%;
    margin: 60px auto 0;
    position: relative;

    div {
      padding: 40px 120px;
      border-top: 1px $border-black solid;

      dt {
        font-size: 1.1em;
        font-weight: bold;

        span {
          margin: 0 20px 0 0;
          display: inline-block;
          color: $red;
        }
      }

      dd {
        margin: 20px 0 0 34px;
        line-height: 1.5;

        span {
          margin: 0 0 0 5px;
          display: inline-block;
          font-size: .7em;
        }
      }

      &:nth-of-type(5) {
        border-bottom: 1px $border-black solid;
      }
    }
    &::before {
      content: '';
      width: 40px;
      height: 100%;
      background-image: url(../../img/arrow-big.svg);
      background-size: 40px 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
} 

@include media(tab) {
  .lowerpage {
    font-size: 16px;
  }

  .services01 {
    padding: 100px 30px 0;

    h3 {
      font-size: 32px;
    }

    ul {
      justify-content: space-between;
      margin: 20px auto 0;

      li {
        width: calc((100% - 60px) / 3);
        margin: 40px 0 0;

        &:nth-of-type(1),
        &:nth-of-type(4) {
          margin: 40px 0 0;
        }
  
        &:nth-of-type(5),
        &:nth-of-type(7) {
          margin: 40px 0 0;
        }

        a {
          h4 {
            line-height: 1.1;
            white-space: pre-line;
          }
        }
      }
    }
  }

  .services02 {
    margin: 100px 0 0 0;
    padding: 100px 30px 100px;

    h3 {
      font-size: 32px;
    }

    dl {
      div {
        padding: 40px 40px 40px 60px;
      }
    }
  }
}

@include media(sp) {
  .lowerpage {
    padding: 40px 20px 0;
    text-align: left;

      br {
        display: none;
      }
  }

  .services01 {
    padding: 80px 0 0 0;

    h3 {
      margin: 0 0 30px 0;
      font-size: 1.5em;
    }

    ul {
      width: 100%;
      max-width: 350px;
      margin: 0 auto 60px;

      li {
        width: 100%;
        margin: 40px 0 0;

        &:nth-of-type(1),
        &:nth-of-type(5) {
          margin: 40px 0 0;
        }
  
        &:nth-of-type(4),
        &:nth-of-type(7) {
          margin: 40px 0 0;
        }

        a {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          h4 {
            width: 100%;
            margin: 10px 0 0;
            font-size: 16px;
          }

          p {
            font-size: 14px;
          }

          small {
            position: relative;
            display: inline-block;
            margin: 10px auto 0 0;
            font-size: 14px;

            &::after {
              position: absolute;
              top: 50%;
              right: -15px;
              transform: translate(0, -50%);
              width: 6px;
              height: 10px;
              background: no-repeat url(../../img/arrow-rb.svg);
              background-size: 100%;
              content: '';
            }
          }
        }
      }
    }
  }

  .services02 {
    margin: 60px 0 0 0;
    padding: 80px 20px 60px;

    h3 {
      margin: 0 0 30px 0;
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }

    dl {
      margin: 30px auto 0;

      div {
        padding: 40px 10px 40px 50px;
        dt {
          font-size: .9em;
          span {
            margin: 0 10px 0 0;
          }
        }

        dd {
          margin: 10px 0 0 22px;
          font-size: .9em;
        }
      }
    }
  }
}