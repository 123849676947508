@import '../variables';

.lowerpageToptext {
  width: 100%;
  padding: 100px 0 0;
  line-height: 1.75;
  font-size: 1.2em;
  text-align: center;
  background-color: $white;
  position: relative;
  z-index: 10;

  .sp {
    display: none;
  }
}

.strength01 {
  padding: 140px 0 0 0;

  h3 {
    margin: 0 0 60px 0;
    text-align: center;
    font-size: 2.2em;
    font-family: 'Noto Sans JP medium';
    font-weight: bold;
    letter-spacing: .05em;
  }
  
  article {
    display: flex;
    width: 100%;

    > div:nth-of-type(1) {
      width: 60%;
      padding: 110px 6%;
      background-color: $red;
      position: relative;

      > span {
        color: white;
        font-size: 18px;
        font-weight: bold;
        font-family: 'PT Serif', serif;
      }

      h4 {
        margin: 10px 0 0 0;
        color: $white;
        font-size: 24px;
        font-family: 'Noto Sans JP medium';
        font-weight: bold;
        line-height: 1.5;
        text-align: left;
      }

      p {
        margin: 20px 0 0 0;
        width: 100%;
        color: $white;
        line-height: 1.75;
      }

      a {
        margin: 40px 0 0 0;
      }
    }

    > div:nth-of-type(2) {
      width: 40%;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }

    &:nth-of-type(2n) {
      display: flex;
      flex-direction: row-reverse;

      > div:nth-of-type(1) {
        width: 60%;
        padding: 110px 6%;
        background-color: $white;
        position: relative;
  
        > span {
          color: $black;
          font-size: 18px;
          font-weight: bold;
          font-family: 'PT Serif', serif;
        }
  
        h4 {
          margin: 10px 0 0 0;
          color: $black;
          font-size: 24px;
          font-family: 'Noto Sans JP medium';
          font-weight: bold;
          line-height: 1.5;
          text-align: left;
        }
  
        p {
          margin: 20px 0 0 0;
          width: 100%;
          color: $black;
          line-height: 1.75;
        }
  
        a {
          margin: 40px 0 0 0;
        }
      }

      > div:nth-of-type(2) {
        width: 40%;
  
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
      }
    }
  }
}

.strength02 {
  padding: 140px 30px 100px;
  background-color: $gray;
  text-align: center;

  h3 {
    margin: 0 0 30px 0;
    text-align: center;
    font-size: 2.2em;
    font-family: 'Noto Sans JP medium';
    font-weight: bold;
    letter-spacing: .05em;
  }

  p:nth-of-type(1) {
    max-width: 700px;
    width: 100%;
    margin: 0 auto 40px;
    text-align: left;
    line-height: 1.5;
  }
  
  dl {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    background-color: $white;
    text-align: left;

    > div {
      padding: 40px 0 50px;
      
      dt {
        max-width: 700px;
        width: 100%;
        margin: 0 auto 30px;
        font-family: 'Noto Sans JP medium';
        font-size: 1.1em;

        span {
          display: inline-block;
          padding: 10px 25px;
          margin: 0 40px 0 0;
          background-color: $black;
          color: $white;
        }
      }
      dd {
        max-width: 700px;
        width: 100%;
        margin: 0 auto;
        line-height: 1.5;
      }

      & + div {
        border-top: 1px $border-black solid;
        position: relative;

        &::after {
          content: '';
          width: 30px;
          height: 30px;
          background-image: url(../../img/arrow-steps.svg);
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          top: -10px;
          left: 50%;
          z-index: 1;
          transform: translateX(-50%);
        }
      }
    }
  }
  
  p:nth-of-type(2) {
    max-width: 960px;
    width: 100%;
    margin: 30px auto 0;
    text-align: left;
    line-height: 1.5;
  }
}

@include media(tab) {
  .lowerpageToptext {
    font-size: 16px;
  }

  .strength01 {
    article {
      > div:nth-of-type(1) {
        padding: 80px 30px;

        h4 {
          font-size: 20px;

          br {
            display: none;
          }
        }
      }

      &:nth-of-type(2n) {
        > div:nth-of-type(1) {
          padding: 80px 30px;

          h4 {
            font-size: 20px;

            br {
              display: none;
            }
          }
        }
      }
    }

    & + article {
      margin: 200px 0 0 0;
    }
  }
}

@include media(sp) {
  .lowerpageToptext {
    padding: 40px 20px 0;
    text-align: left;

      .pc {
        display: none;
      }
  }

  .strength01 {
    padding: 80px 0 0 0;

    h3 {
      margin: 0 0 30px 0;
      font-size: 24px;
    }

    article {
      flex-direction: column-reverse;

      > div:nth-of-type(1) {
        width: 100%;
        padding: 40px 20px;

        a {
          margin: 40px auto 0;
        }
      }

      > div:nth-of-type(2) {
        width: 100%;
        height: 140px;
      }

      &:nth-of-type(2n) {
        flex-direction: column-reverse;

        > div:nth-of-type(1) {
          width: 100%;
          padding: 40px 20px;
  
          a {
            margin: 40px auto 0;
          }
        }
  
        > div:nth-of-type(2) {
          width: 100%;
          height: 140px;
        }
      }
    }
  }

  .strength02 {
    padding: 80px 20px 60px;

    h3 {
      font-size: 1.5em;
    }

    p:nth-of-type(1) {
      font-size: .9em;
      margin: 0 auto 20px;
    }

    dl {
      > div {
        padding: 20px 0;

        dt {
          margin: 0 auto 20px;
          padding: 0 10px;
          font-size: 16px;

          span {
            padding: 5px 10px;
            margin: 0 10px 0 0;
          }
        }

        dd {
          padding: 0 10px;
          font-size: .9em;
        }

        & + div {
          &::after {
            width: 20px;
            height: 20px;
            top: -8px;
          }
        }
      }
    }

    p:nth-of-type(2) {
      margin: 20px auto 0;
      font-size: .9em;
    }
  }
}