@import '../variables';

.scrollTop {
  width: 50px;
  height: 60%;
  position: absolute;
  bottom: 0;
  right: 10px;

  > div {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 60%;
    right: 10px;
    z-index: 900;
    transform: translateY(-50%);
    background-color: $black;
    box-shadow: 0 4px 16px $border-black;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    opacity: 0;
    visibility: hidden;
    transition: .3s;


    img {
      width: 20px;

      transform: translateY(0);
      transition: .3s;
    }

    &:hover {
      img {
        transform: translateY(-5px);
      }
    }
  }

  &.show {
    > div{
      opacity: 1;
      visibility: visible;
    }
  }


}

@include media(tab) {
  .scrollTop:hover {
    > div {
      img {
        transform: translateY(0);
      }
    }
  }
}

@include media(sp) {
  .scrollTop {
    > div {
      width: 40px;
      height: 40px;
      right: 5px;

      img {
        width: 10px;
      }
    }
  }
}