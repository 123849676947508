@import '../variables';

.top {
  position: relative;
  overflow: hidden;
}

.category {
  position: absolute;
  bottom: 1px;
  left: 15%;

  ul {
    display: flex;

    li {
      padding: 20px 10px;
      cursor: pointer;
      border: 1px $border-black solid;
      border-bottom: unset;

      background-color: $white;
      color: $black;
      transition: .3s;

      &:hover {
        background-color: $black;
        color: $white;
      }

      &.active {
        background-color: $black;
        color: $white;
      }
      & + li {
        border-left: unset;
      }
    }
  }
}

.works {
  margin: 0 0 100px 0;
  padding: 120px 30px 0;

  h3 {
    text-align: center;
    font-size: 26px;
    font-family: 'Noto Sans JP medium';
    font-weight: bold;
    letter-spacing: .05em;

    opacity: 0;
    transition: .3s;

    &.active {
      opacity: 1;
    }
  }

  article {
    max-width: 960px;
    width: 100%;
    margin: 60px auto 0;
    padding: 40px 30px;
    border: 1px $border-black solid;
    display: flex;
    justify-content: space-between;

    transform: translateY(30px);
    opacity: 0;
    transition: .3s;

    &.active {
      transform: translateY(0);
      opacity: 1;
    }

    div:nth-of-type(1) {
      width: 48%;
      
      .title {
        font-size: .8em;
        font-family: 'Noto Sans JP medium';
        font-weight: bold;
      }

      h4 {
        margin: 8px 0 10px;
        font-size: 1.1em;
        font-family: 'Noto Sans JP medium';
        font-weight: bold;
      }

      a {
        display: inline-block;
        padding: 5px 0;
        font-size: .8em;
        position: relative;
        text-decoration: underline;
        transition: .3s;

        &:hover {
          color: $red;
        }
      }

      span {
        font-size: .8em;
      }

      ul {
        margin: 5px 0 0 0;
        display: flex;
        flex-wrap: wrap;

        li {
          margin: 5px 7px 0 0;
          padding: 5px 10px;
          color: $white;
          background-color: $black;
          font-size: .6em;
          font-family: 'Noto Sans JP medium';
        }
      }

      .text {
        margin: 20px 0 0 0;
        line-height: 1.35;
      }

      .period {
        margin: 20px 0 0 0;
        font-size: 12px;
      }
    }
    
    div:nth-of-type(2) {
      position: relative;
      width: 45%;

      &::before {
        display: block;
        width: 100%;
        height: 61.8%;
        content: '';
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  button {
    display: block;
    width: 320px;
    padding: 20px 0;
    margin: 80px auto;
    color: $black;
    font-weight: bold;
    text-align: center;
    border: 1px solid $black;
  
    background-color: unset;
    transition: .3s;
  
    &:hover {
      color: $white;
      background-color: $black;
    }
  }
}

@include media(tab) {
  .category {
    position: unset;
    width: 100%;

    ul {
      width: 100%;
      flex-wrap: wrap;

      li {
        width: calc(100% / 2);
        padding: 10px 8px;
        font-size: 14px;
        text-align: center;
        border: unset;
        border-bottom: 1px $border-black solid;

        &:nth-of-type(2n - 1) {
          border-right: 1px $border-black solid;
        }
      }
    }
  }

  .works {
    article {
      width: 100%;
      max-width: 350px;
      margin: 0 auto;
      border: unset;
      border-bottom: 1px $border-black solid;
      align-items: center;
      flex-direction: column-reverse;

      div:nth-of-type(1) {
        width: 100%;
        margin: 30px 0 0 0;

        ul {
          li {
            white-space: nowrap;
          }
        }
      }
      
      div:nth-of-type(2) {
        width: 100%;

        &::before {
          height: 180px;
        }
      }
    }
  }
}

@include media(sp) {
  .works {
    padding: 80px 0 0 0;

    h3 {
      font-size: 24px;
    }

    article {
      padding: 40px 18px;

      div:nth-of-type(1) {
        .title {
          font-size: 14px;
        }

        h4 {
          font-size: 17px;
        }
      }
    }
  }
}