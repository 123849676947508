@import '../variables';

.topicPath {
  width: 100%;
  padding: 10px 6%;
  border-bottom: 1px $border-black solid;

  ul {
    display: flex;
    color: $black-light;
    font-size: 0.75em;

    li {
      margin: 0 10px 0 0;

      a {
        font-size: 14px;
      }
    }

    span {
      margin: 0 10px 0 0;
      width: 12px;
      display: inline-block;

      img {
        width: 100%;
      }
    }
  }
}

@include media(tab) {
  .topicPath {
    padding: 10px 30px;
  }
}

@include media(sp) {
  .topic-path {
    padding: 10px 20px;
    font-size: .7em;

    span {
      width: 10px;
    }
  }
}