@import '../variables';

.company01 {
  margin: 0 0 100px 0;
  padding: 100px 6% 0;

  h3 {
    text-align: center;
    font-size: 26px;
    font-family: 'Noto Sans JP medium';
    font-weight: bold;
    letter-spacing: .05em;
  }

  dl {
    max-width: 800px;
    width: 100%;
    margin: 60px auto 0;

    > div {
      display: flex;

      dt {
        max-width: 145px;
        width: 100%;
        font-family: 'Noto Sans JP medium';
        font-weight: bold;
        font-size: 1.1em;
      }

      dd {
        line-height: 1.5;
        display: flex;

        span:nth-of-type(1) {
          width: 120px;
        }
        span:nth-of-type(2) {
          width: calc(100% - 120px);
        }

        & + dd {
          margin: 15px 0 0 0;
        }
      }

      & + div {
        margin: 40px 0 0 0;
      }

      &:nth-of-type(8) {
        span:nth-of-type(1) {
          width: 150px;
        }

        span:nth-of-type(2) {
          width: calc(100% - 150px);
        }
      }
    }
  }
}

.company02 {
  margin: 80px 0 0 0;
  padding: 120px 30px 120px;
  background-color: $gray;

  h3 {
    text-align: center;
    font-size: 26px;
    font-family: 'Noto Sans JP medium';
    font-weight: bold;
    letter-spacing: .05em;
  }

  article {
    max-width: 960px;
    width: 100%;
    margin: 60px auto 0;
    display: flex;
    justify-content: flex-end;
    position: relative;

    div:nth-of-type(1) {
      width: 240px;
      height: 260px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    div:nth-of-type(2) {
      max-width: 760px;
      width: 100%;
      padding: 50px 40px 50px 90px;
      background-color: $white;
      letter-spacing: .08em;

      p {
        line-height: 1.5;
        margin: 30px 0 0 0;
      }
    }
  }
}

@include media(tab) {
  .company01 {
    padding: 100px 30px 0;
  }

  .company02 {
    article {
      div:nth-of-type(1) {
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }
      div:nth-of-type(2) {
        margin: 200px auto 0;
        padding: 100px 40px 50px;
      }
    }
  }
}

@include media(sp) {
  .company01 {
    padding: 80px 30px 0;

    h3 {
      font-size: 24px;
    }

    dl {
      margin: 40px 0 0 0;

      > div {
        flex-direction: column;

        dt {
          font-size: 16px;
        }

        dd {
          margin: 10px 0 0 0;
          font-size: 14px;
          line-height: 1.25;

          & + dd {
            margin: 5px 0 0 0;
          }

          span {
            font-size: 14px;
            line-height: 1.25;
          }

          span:nth-of-type(1) {
            width: 100px;
          }
  
          span:nth-of-type(2) {
            width: calc(100% - 100px);
          }
        }

        & + div {
          margin: 25px 0 0 0;
        }
      }
    }
  }

  .company02 {
    margin: 60px 0 0 0;
    padding: 80px 8px 60px;

    h3 {
      font-size: 24px;
    }

    article {
      margin: 40px auto 0;

      div:nth-of-type(2) {
        padding: 80px 22px 40px;
      }
    }
  }
}