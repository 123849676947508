@import '../variables';

.lowerpage {
  height: 400px;
  border-bottom: 1px $border-black solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  div:nth-of-type(1) {
    max-width: 400px;
    width: 100%;
    margin: 0 0 0 15%;

    span {
      font-size: 0.9em;
      letter-spacing: .05em;
      margin: 0 0 5px 0;
      display: block;
    }

    h1 {
      font-size: 0.9em;
      letter-spacing: .05em;
      margin: 0 0 5px 0;
      display: block;
      font-family: 'Noto Sans JP medium';
    }

    h2 {
      padding: 0 0 10px 0;
      font-size: 60px;
      font-weight: bold;
      position: relative;
      font-family: 'PT Serif', serif;

      &::after {
        content: '';
        width: 130px;
        height: 2px;
        background-color: $red;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    p {
      margin: 20px 0 0 0;
      font-weight: bold;
      line-height: 1.3;

      img {
        height: 25px;
        margin: 0 0 0 10px;
        display: none;

        &.show {
          display: inline-block;
        }
      }
    }
  } 
  
  div:nth-of-type(2) {
    width: 45%;
    height: 100%;
    background-color: $black;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      opacity: .5;
      object-fit: cover;
    }

    ul {
      position: absolute;
      right: 60px;
      bottom: 60px;

      li {
        color: $white;
        position: relative;
        cursor: pointer;

        & + li {
          margin: 15px 0 0 0;
        }

        &::after {
          content: '';
          height: 1px;
          width: 20px;
          background-color: $white;
          position: absolute;
          left: -35px;
          top: 50%;

          transition: .3s;
        }

        &:hover::after {
          width: 30px;
        }

        a {
          display: inline-block;
          padding: 5px;
          width: 100%;
          height: 100%;
          font-family: 'Noto Sans JP medium';
        }
      }
    }
  }
  
  .relatedContentLink {
    position: absolute;
    bottom: 0;
    left: 15%;
    z-index: 1;

    ul {
      display: flex;

      li {
        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          padding: 20px 10px;
          border: 1px $border-black solid;
          border-bottom: unset;
        }

        &.lists {
          background-color: $white;
          color: $black;
          transition: .3s;

          &:hover {
            background-color: $black;
            color: $white;
          }

          &.active {
            background-color: $black;
            color: $white;
          }
        }

        & + li {
          a {
            border-left: unset;
          }
        }
      }
    }
  } 
}

@include media(tab) {
  .lowerpage {
    div:nth-of-type(1) {
      margin: 0 0 0 30px;
    }
    
    div:nth-of-type(2) {
      ul {
        right: 30px;
        font-size: .9em;
      }
    }

    .relatedContentLink {
      left: 30px;
      font-size: .8em;

      ul {
        li {
          a {
            padding: 15px 5px;
          }
        }
      }
    }
  }
}

@include media(sp) {
  .lowerpage {
    height: auto;
    flex-direction: column;
    justify-content: unset;
    border-bottom: unset;

    div:nth-of-type(1) {
      max-width: unset;
      width: 100%;
      margin: 30px auto 30px 0;
      padding: 0 20px;

      h2 {
        font-size: 3em;

        &::after {
          width: 100px;
        }
      }

      p {
        font-size: .8em;
      }
    }

    div:nth-of-type(2) {
      width: 100%;
      height: 170px;

      ul {
        width: 100%;
        height: 140px;
        padding: 0 30px 0 0;
        right: 0;
        bottom: 20px;
        font-size: .9em;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: flex-end;

        li {
          width: 120px;
          
          a {
            font-size: 14px;
          }

          &::after {
            width: 15px;
            left: -15px;
          }

          &:hover::after {
            width: 15px;
          }

          & + li {
            margin: 10px 0 0 0;
          }
        }
      }

    }

    .relatedContentLink {
      position: unset;
      width: 100%;

      ul {
        width: 100%;
        flex-wrap: wrap;

        li {
          width: calc(100% / 2);
          text-align: center;

          a {
            border: unset;
            border-bottom: 1px $border-black solid;
            
            &:nth-of-type(2n - 1) {
              border-right: 1px $border-black solid;
            }
          }
        }
      }
    }
  }
}