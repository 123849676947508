@import '../variables';

.button {
  display: block;
  margin: 0 auto;
  max-width: 300px;
  width: 100%;

  span {
    width: 100%;
    padding: 20px 0 20px 40px;
    color: $white;
    font-weight: bold;
    text-align: left;
    border: 1px solid $border-black;
    display: inline-block;
    position: relative;
    
    background-color: $black;
    transition: .3s;
    
    &::after {
      content: "";
      width: 12px;
      height: 12px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
      
      background-image: url(../../img/arrow-rw.svg);
      transition: .3s;
    }
  }
  &:hover {
    span {
      color: $black;
      background-color: $white;
      
      &::after {
        background-image: url(../../img/arrow-rb.svg);
      }
    }
  }
}