@import '../variables';

.top {
  width: 100%;
  height: 80vh;
  margin: 0 auto;
  background-image: url(../../img/index01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  h2 {
    text-align: center;
    font-size: 1.5em;
    color: $white;
    letter-spacing: .1em;
    line-height: 1.5;
    position: absolute;
    top: 35%;
    left: 15%;
    font-family: "Noto Sans JP medium";
    font-weight: bold;

    .spOnly {
      display: none;
    }
  }

  span {
    color: $white;
    font-weight: bold;
    position: absolute;
    bottom: 90px;
    left: 50%;
    transform: translateX(-50%);

    @keyframes scroll{
      0% {
        transform: scale(1, 0);
        transform-origin: 0 0;
      }
      35% {
        transform: scale(1, 1);
        transform-origin: 0 0;
      }
      35.1% {
        transform: scale(1, 1);
        transform-origin: 0 100%;
      }
      70%, 100% {
        transform: scale(1, 0);
        transform-origin: 0 100%;
      }
    }

    &::after {
      content: '';
      width: 2px;
      height: 80px;
      background-color: $white;
      position: absolute;
      bottom: -90px;
      left: 50%;
      transform: translateX(50%);
      animation: scroll 2.2s cubic-bezier(0.76, 0, 0.3, 1) infinite;
    }
  }
}

.strength {
  width: 100%;
  padding: 100px 30px 70px;
  text-align: center;
  position: relative;

  h3 {
    padding: 0 0 20px 0;
    font-size: 1.5em;
    font-family: "Noto Sans JP medium";
    font-weight: bold;
    position: relative;
    color: $white;

    &::after {
      @include index-underline($white)
    }
  }

  p {
    margin: 20px auto 60px;
    color: $white;
    font-size: 1.1em;
    line-height: 1.75;
    font-family: "Noto Sans JP medium";

    .spOnly {
      display: none;
    }
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-image: url(../../img/index02.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  > a {
    @include main-button();
  }
}

.service {
  width: 100%;
  padding: 100px 6% 70px;
  background-color: $gray;
  text-align: center;
  
  h3 {
    @include index-section-title($black);
    &::after {
      @include index-underline($red);
    }
  }

  > p {
    margin: 20px 0 0 0;
    font-size: 1.1em;
    line-height: 1.75;
    font-family: "Noto Sans JP medium";

    .spOnly {
      display: none;
    }
  }

  ul {
    width: 100%;
    margin: 40px auto 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      width: calc((100% - 60px) / 4);
      margin: 40px 10px 0;

      &:nth-of-type(1),
      &:nth-of-type(5) {
        margin: 40px 10px 0 0;
      }

      &:nth-of-type(4),
      &:nth-of-type(7) {
        margin: 40px 0 0 10px;
      }

      a {
        display: block;
        width: 100%;

        div {
          position: relative;
          width: 100%;
          height: 61.8%;
          overflow: hidden;

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            content: '';
          }

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            color: $white;
            font-size: 14px;
            font-family: 'Noto Sans JP medium';
            content: '詳しく見る ＞';
            opacity: 0;
            visibility: hidden;
            transition: .3s;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: .3s;
          }
        }

        h4 {
          position: relative;
          display: flex;
          align-items: center;
          height: 37px;
          margin: 20px 0 0 0;
          padding: 0 0 0 35px;
          white-space: pre-wrap;
          font-family: "Noto Sans JP medium";
          text-align: left;
        }

        p {
          margin: 8px 0 0 0;
          text-align: left;
          font-size: 15px;
          line-height: 1.6;
        }

        small {
          display: none;
        }

        &:hover {
          div {
            img {
              transform: scale(1.1)
            }

            &::after {
              opacity: 1;
              visibility: visible
            }
          }
        }
      }

      $icons: 'service01', 'service02', 'service03', 'service04', 'service05', 'service06', 'service07';
      @each $icon in $icons {
        &.#{$icon} {
          a {
            h4::before {
              position: absolute;
              top: 55%;
              left: 0;
              transform: translate(0, -50%);
              width: 25px;
              height: 25px;
              background: no-repeat url(../../img/#{$icon}.png);
              background-size: 100%;
              content: '';
            }
          }
        }
      }
    }
  }
  
  > a {
    @include main-button();
  }
}

.works {
  padding: 100px 6% 70px;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  h3 {
    @include index-section-title($black);

    &::after {
      @include index-underline($red);
    }
  }

  > p {
    margin: 20px 0 0 0;
    font-size: 1.1em;
    line-height: 1.75;
    font-family: "Noto Sans JP medium";

    br {
      display: none;
    }
  }
  
  > article {
    max-width: 1040px;
    width: 100%;
    margin: 70px auto;
    position: relative;
    
    .works02 {
      max-width: 960px;
      width: 80%;
      margin: 0 auto;
      overflow: hidden;

      img {
        width: 100%;

        transform: scale(1);
        transition: .2s;
      }
    }
    
    .works01 {
      width: 350px;
      height: 210px;
      background-color: $white;
      box-shadow: $shadow-black;
      text-align: left;
      position: absolute;
      left: 0;
      bottom: -20%;
      z-index: 1;

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 40px 20px 0;
        position: relative;
        outline: none;

        ul {
          display: flex;

          li {
            font-size: .75em;
            font-weight: bold;

            & + li {
              &::before {
                content: '・';
                font-size: .75em;
                font-weight: bold;
                margin: 0 3px;
              }
            }
          }
        }

        h4 {
          padding: 10px 0 10px;
          font-size: 1.25em;
          font-weight: bold;
        }

        p {
          font-size: .75em;
          color: $black-light;
        }

        &::before {
          content: '';
          width: 100%;
          height: 10px;
          background-color: $red;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      @keyframes redline {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }

      &:hover {
        a::before {
          animation: redline .4s ease-in-out both;
        }
      }
      &:hover ~ div:nth-of-type(2){
        img {
          transform: scale(1.05);
          transition: .4s;
        }
      }

      > div {
        ul {
          padding: 0 20px 0 0;
          bottom: -35px;
          text-align: right;
        }
        
        li {
          margin: 0 auto 0 0;
          text-align: unset;
        }
        
      }
    }
  }

  > div {
    max-width: 1100px;
    width: 100%;
    margin: 200px auto 50px;
    display: flex;
    justify-content: space-between;

    article {
      width: calc((100% - 60px) / 3);
      margin: 0 10px;

      a {
        display: inline-block;
        width: 90%;
        height: 100%;
        margin: 0 auto;
        text-align: left;

        > div {
          position: relative;

          div {
            position: relative;
            width: 100%;
            height: 61.8%;
            overflow: hidden;
  
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              display: block;
              width: 100%;
              height: 100%;
              content: '';
            }

            &::after {
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
              color: $white;
              font-size: 14px;
              font-family: 'Noto Sans JP medium';
              content: '詳しく見る ＞';
              opacity: 0;
              visibility: hidden;
              transition: .3s;
            }

            img {
              width: 100%;
              height: 100%;
              transform: scale(1);
              transition: .3s;
            }
          }

          span {
            padding: 5px 10px;
            color: $white;
            background-color: $red;
            font-size: 0.75em;
            font-weight: bold;
            position: absolute;
            bottom: -10px;
            left: -10px;
            z-index: 1;
          }
        }

        h4 {
          margin: 25px 0 0;
          font-size: 15px;
          font-family: 'Noto Sans JP medium';
          font-weight: bold;
          line-height: 1.35;
          letter-spacing: 0;
        }

        ul {
          margin: 10px 0 0 0;
          display: flex;
          flex-wrap: wrap;
  
          li {
            padding: 5px 10px;
            border: 1px $border-black solid;
            font-size: .6em;
            font-weight: bold;
  
            & + li {
              margin: 0 0 0 10px;
            }
          }
        }

        &:hover {
          > div {

            div::after {
              opacity: 1;
              visibility: visible;
            }

            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}

@include media(tab) {
  .top {
    h2 {
      text-align: left;
      left: 10%;
    }
  }

  .service {
    padding: 100px 30px 70px;

    ul {
      justify-content: space-between;
      margin: 20px auto 60px;

      li {
        width: calc((100% - 60px) / 3);
        margin: 40px 0 0;

        &:nth-of-type(1),
        &:nth-of-type(4) {
          margin: 40px 0 0;
        }
  
        &:nth-of-type(5),
        &:nth-of-type(7) {
          margin: 40px 0 0;
        }

        a {
          h4 {
            line-height: 1.1;
            white-space: pre-line;
          }
        }
      }
    }
  }
}

@include media (sp) {
  .top {
    h2 {
      font-size: 1.3em;
      left: 30px;

      .spOnly {
        display: block;
      }
    }
  }

  .strength {
    h3 {
      font-size: 1.3em;
    }

    p {
      font-size: .9em;
      line-height: 1.5;

      .spOnly {
        display: block;
      }
    }

    a {
      font-size: .9em;
    }
  }

  .service {
    padding: 60px 30px;

    h3 {
      font-size: 3em;
    }

    p {
      font-size: .9em;
      line-height: 1.5;

      .spOnly {
        display: block;
      }
    }

    ul {
      margin: 0 auto 60px;

      li {
        width: 100%;
        max-width: 350px;
        margin: 40px 0 0;

        &:nth-of-type(1),
        &:nth-of-type(5) {
          margin: 40px 0 0;
        }
  
        &:nth-of-type(4),
        &:nth-of-type(7) {
          margin: 40px 0 0;
        }

        a {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          h4 {
            width: 100%;
            margin: 10px 0 0;
            font-size: 16px;
          }

          p {
            font-size: 14px;
          }

          small {
            position: relative;
            display: inline-block;
            margin: 10px auto 0 0;
            font-size: 14px;

            &::after {
              position: absolute;
              top: 50%;
              right: -15px;
              transform: translate(0, -50%);
              width: 6px;
              height: 10px;
              background: no-repeat url(../../img/arrow-rb.svg);
              background-size: 100%;
              content: '';
            }
          }
        }
      }
    }
  }

  .works {
    padding: 60px 30px 80px;

    h3 {
      font-size: 3em;
    }

    > p {
      font-size: .9em;
      line-height: 1.5;

      br {
        display: block;
      }
    }

    > article {
      display: none;
    }

    > div {
      max-width: 350px;
      margin: 40px auto 60px;
      flex-direction: column;

      article {
        width: 100%;
        height: auto;

        & + article {
          margin: 30px 0 0 0;
        }

        a {
          width: 100%;

          ul {
            padding: 0;
          }
        }
      }
    }
    
    a {
      font-size: .9em;
    }
  }
}